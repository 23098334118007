html {
    background-color: #000;
    scroll-behavior: smooth;
  }
  body {
    background-color: #000;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)),
      url(../images/main-bg.png);
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: top center !important;
    font-family: Poppins, sans-serif;
    font-size: 16px;
  }
  body.privacy {
      background: none;
      background-color: white;
  }
  .privacy-container .row {
      width: 100%;
      margin: 0 auto;
  }
  .text-color-white {
    color: #fff;
  }
  .text-color-black {
    color: #000;
  }
  .cookie-banner {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #333;
      color: #fff;
      padding: 15px;
      text-align: center;
      /* display: none; */
      align-items: center;
      justify-content: center;
  }
  .cookie-banner p {
      margin: 0;
  }
  .cookie-banner button {
      background-color: #0ed085;
      color: #333;
      border: none;
      padding: 10px 30px;
      cursor: pointer;
      margin-left: 15px;
  }
  
  .cookie-banner button:hover {
      background-color: #0fe090;
  }
  .link-btn {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    padding: 6px 12px;
    display: block;
    width: fit-content;
    margin: 0 auto;
    border-radius: 4px;
  }
  .link-btn:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0b5ed7;
    border-color: #0a58ca;
  }
  .hero-link-btn {
    padding: 16px 48px;
  }
  header {
    background: #0000007f;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header img {
    width: 82px;
  }
  
  .age-check-popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000000d9;
      z-index: 200;
  }
  .age-check-popup.hidden {
      display: none;
  }
  .age-check-popup .popup-inner-wrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 4px;
      padding: 16px;
      max-width: 500px;
  }
  .age-check-popup .popup-inner-wrapper img {
      width: 48px;
      height: 48px;
      margin: 0 auto 30px;
      display: block;
  }
  .age-check-popup .popup-inner-wrapper h3 {
      border-bottom: 1px solid black;
      padding-bottom: 10px;
      font-size: 24px;
      font-weight: 700;
  }
  .age-check-popup .popup-inner-wrapper p {
      margin-top: 40px;
      border-bottom: 1px solid black;
      padding-bottom: 30px;
  }
  .age-check-popup .popup-inner-wrapper button {
      width: 400px;
      border: none;
      margin: 0 auto;
      display: block;
      background-color: #198754;
      border-radius: 4px;
      color: white;
      padding: 10px;
  }
  .hero-section {
    padding-top: 50px;
    height: calc(100vh - 116px);
    height: 100%;
  }
  .hero-section .container {
    position: relative;
    height: calc(100vh - 116px);
  }
  .hero-section .content-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hero-section .content-block .hero-title {
    font-weight: 700;
    font-size: 35px!important;
  }
  .hero-section .content-block .hero-subtitle {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
  }
  .hero-section .content-block .hero-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .top-games .container {
      margin-top: 20px;
  }
  .top-games .top-games-card {
    width: calc((100% - 48px) / 3);
    border-radius: 4px;
    border: 1px solid #fff;
    margin-bottom: 24px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    margin-right: 24px;
  }
  .top-games .top-games-card:nth-child(3n) {
      margin-right: 0;
  }
  .top-games .top-games-card img {
    width: 100%;
  }
  .top-games .top-games-card .card-bottom-wrapper {
    padding: 10px;
    position: relative;
  }
  .top-games .top-games-card .game-name {
    margin-bottom: 60px;
  }
  .top-games .top-games-card .game-card-link {
    width: calc(100% - 20px);
    position: absolute;
    bottom: 10px;
    left: 0;
    margin-left: 10px;
  }
  .top-games.top-games-no-btn .bottom-btn {
      display: none;
  }
  .info .container {
      margin-top: 20px;
      margin-bottom: 40px;
  }
  .info .container .info-title {
      font-size: 32px;
      font-weight: 500;
  }
  .info .container .info-text {
      max-width: 990px;
  }
  .info .container .sponsors-block {
      margin-top: 40px;
  }
  .info .container .sponsors-block img + img {
      margin-left: 20px;
  }
  .info.info-small h2 {
      display: none;
  }
  .info.info-small p {
      display: none;
  }
  .subscribe {
      background-color: #733535 !important;
  }
  .subscribe .container {
      padding-top: 40px;
      padding-bottom: 20px;
  }
  .subscribe .container .subscribe-form {
      background: rgb(255 255 255 / 20%);
      color: black;
      width: 630px;
      margin: 50px auto 0;
      padding: 24px;
  }
  
  .subscribe .container .subscribe-form .form-title {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: inherit;
      font-weight: 800;
      text-align: center;
  }
  .subscribe .container .subscribe-form .form-text {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 2px;
      font-weight: 400;
      color: inherit;
      text-align: center;
  }
  .subscribe .container .subscribe-form .input-wrapper {
      width: 470px;
      margin: 0 auto;
      
  }
  .subscribe .container .subscribe-form .input-wrapper input {
      width: calc(100% - 135px);
      border: none;
      font-size: 16px;
      padding: 6px 12px;
      line-height: 24px;
  }  
  .subscribe .container .subscribe-form .input-wrapper input::placeholder {
      color: gray;
  }
  .subscribe .container .subscribe-form .input-wrapper button {
      width: 135px;
      background-color: #0ed085;
      text-transform: uppercase;
      color: white;
      border: none;
  }
  footer .container {
      text-align: center;
      padding-top: 16px;
      padding-bottom: 16px;
  }
  footer .container p {
      margin: 0;
      color: white;
      font-size: 16px;
      line-height: 20px;
  }
  footer .container p a {
      color: white;
      text-decoration: underline;
  }
  
  .game-frame {
      max-width: 1140px;
      border-radius: 4px;
      background-color: white;
      margin: 30px auto 30px;
  }
  .game-frame .container {
      width: 100%;
      padding: 0;
  }
  .game-frame .container .title-block {
      padding: 10px;
  }
  .game-frame .container .title-block h2 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 0;
  }
  .game-frame .container .risk-info-block {
      padding: 10px;
  }
  .game-frame .container .risk-info-block p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
  }
  .game-frame .container .modal-window {
      position: relative !important;
      padding-bottom: 56.25% !important;
      padding-top: 25px !important;
      height: 0 !important;
  }
  .game-frame iframe {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
  }
  
  @media screen and (max-width: 768px) {
      .subscribe .container .subscribe-form {
          width: 100%;
      }
      .subscribe .container .subscribe-form .input-wrapper {
          width: 100%;
      }
      .hero-section .content-block {
          width: calc(100% - 20px);
          margin: 0 auto;
      }
      .top-games .top-games-card {
          width: 100%;
          margin-right: 0;
      }
      .top-games .top-games-card + .top-games-card {
          margin-top: 20px;
      }
  }